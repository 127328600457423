import React from "react";

const SpeakerOnIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="40" cy="40" r="40" fill="#747474" fillOpacity="0.5" />
    <path
      d="M19.1667 35.8333V44.1666C19.1667 48.3333 21.2501 50.4166 25.4167 50.4166H28.3959C29.1667 50.4166 29.9376 50.6458 30.6042 51.0416L36.6876 54.8541C41.9376 58.1458 46.2501 55.75 46.2501 49.5625V30.4375C46.2501 24.2291 41.9376 21.8541 36.6876 25.1458L30.6042 28.9583C29.9376 29.3541 29.1667 29.5833 28.3959 29.5833H25.4167C21.2501 29.5833 19.1667 31.6666 19.1667 35.8333Z"
      stroke="white"
      strokeWidth="3"
    />
    <path
      d="M52.5 31.6666C56.2083 36.6041 56.2083 43.3958 52.5 48.3333"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.3125 26.4584C62.3333 34.4792 62.3333 45.5209 56.3125 53.5417"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SpeakerOnIcon;
