import React from "react";

const SpeakerOffIcon = () => (
    <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.25 17.4375V15.4375C31.25 9.22913 26.9375 6.85413 21.6875 10.1458L15.6041 13.9583C14.9375 14.3541 14.1666 14.5833 13.3958 14.5833H10.4166C6.24996 14.5833 4.16663 16.6666 4.16663 20.8333V29.1666C4.16663 33.3333 6.24996 35.4166 10.4166 35.4166H14.5833"
      stroke="#525252"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.6875 39.8541C26.9375 43.1458 31.25 40.75 31.25 34.5625V26.9791"
      stroke="#525252"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.1875 19.625C41.0625 24.1042 40.5 29.3333 37.5 33.3333"
      stroke="#525252"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.0625 16.25C47.125 23.5208 46.2083 32.0208 41.3125 38.5417"
      stroke="#525252"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.8333 4.16663L4.16663 45.8333"
      stroke="#525252"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SpeakerOffIcon;
