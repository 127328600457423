import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { generateBubbleChat } from "../apis";
import "../style.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LMSStyle from "./LMSStyle";
import HeygenStyle from "./HeygenStyle";
import DefaultStyle from "./DefaultStyle";
import LMSQuizStyle from "./LMSQuizStyle";
import LMSActivityStyle from "./LMSActivityStyle"; // Import LMSActivityStyle component
import * as sdk from "microsoft-cognitiveservices-speech-sdk";

// HeyGen TTS and avatar streaming constants
const HEYGEN_API_KEY = 'ZThmNmRhYzU4NTE5NDgzOGJlMzBlM2Q4YTU0OGMwNTktMTczMDIyNDAzOA==';
const HEYGEN_SERVER_URL = 'https://api.heygen.com';
const HEYGEN_AVATAR_ID = 'ef08039a41354ed5a20565db899373f3';
const HEYGEN_VOICE_ID = '1bd001e7e50f421d891986aad5158bc8';

const SPEECH_KEY = 'f13aae8580694ea9a1062b7fe8e08f7b';
const SPEECH_REGION = 'eastus';

function BubbleChat({
  activeChat,
  setActiveChat,
  setQueries,
  questionList,
  setQuestionList,
  avatar,
  session_id,
}) {
  const serverAddress = process.env.REACT_APP_URL;
  const activeModel = useSelector((store) => store.auth.activeModel);
  const [question, setQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesContainerRef = useRef(null);
  const timestampedQuestions = useRef([]);
  const location = useLocation();
  const [initialMessage, setInitialMessage] = useState("How can I help you?");
  const [isLMSStyle, setIsLMSStyle] = useState(false);
  const [isHeygenStyle, setIsHeygenStyle] = useState(false);
  const [isLMSQuizStyle, setIsLMSQuizStyle] = useState(false);
  const [isLMSActivityStyle, setIsLMSActivityStyle] = useState(false); // State for LMS Activity style
  const [isOpen, setIsOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  // Typewriter effect and TTS state
  const [displayedText, setDisplayedText] = useState("");
  const [typingIndex, setTypingIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const [skipTyping, setSkipTyping] = useState(false);
  const [isTTSEnabled, setIsTTSEnabled] = useState(true);

  // Input ref for focusing
  const inputRef = useRef(null);

  // Peer connection for WebRTC streaming
  const peerConnection = useRef(null);
  const sessionInfo = useRef(null); // Holds the session info for streaming

  // For voice-to-text (speech recognition)
  const [isListening, setIsListening] = useState(false);

  // Speech SDK references
  const speechConfig = useRef(null);
  const audioConfig = useRef(null);
  const recognizer = useRef(null);
  const player = useRef(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const startingMessage = params.get("starting_message");
    const styleParam = params.get("style");
  
    if (startingMessage) {
      setInitialMessage(startingMessage);
  
      // Set initialMessage to empty after it is displayed
      setTimeout(() => {
        setInitialMessage("");
      }, 0); // Executes immediately after setting the initial message
    }
  
    if (styleParam === "lms") {
      setIsLMSStyle(true);
      setIsOpen(true); // Chat is always open for LMS style
    }
  
    if (styleParam === "heygen") {
      setIsHeygenStyle(true);
      setIsOpen(true); // Chat is always open for heygen style
      initializeSession(); // Start HeyGen avatar session
    }
  
    if (styleParam === "lmsquiz") {
      setIsLMSQuizStyle(true);
      setIsOpen(true); // Chat is always open for LMSQuiz style
    }
  
    if (styleParam === "lmsactivity") {  // New condition for LMSActivity style
      setIsLMSActivityStyle(true);
      setIsOpen(true);
    }
  
    // Initialize speech configuration
    speechConfig.current = sdk.SpeechConfig.fromSubscription(
      SPEECH_KEY,
      SPEECH_REGION
    );
    speechConfig.current.speechRecognitionLanguage = "en-US";
  
    // Initialize audio configuration
    audioConfig.current = sdk.AudioConfig.fromDefaultMicrophoneInput();
    recognizer.current = new sdk.SpeechRecognizer(
      speechConfig.current,
      audioConfig.current
    );
  
    // Set up recognizer events
    recognizer.current.recognized = (s, e) => processRecognizedTranscript(e);
    recognizer.current.recognizing = (s, e) => processRecognizingTranscript(e);
  
    return () => {
      recognizer.current.stopContinuousRecognitionAsync(() => {
        setIsListening(false);
      });
    };
  }, [location]);
  

  const processRecognizedTranscript = (event) => {
    const result = event.result;
    if (result.reason === sdk.ResultReason.RecognizedSpeech) {
      const transcript = result.text;
      setQuestion(transcript);
    }
  };

  const processRecognizingTranscript = (event) => {
    const result = event.result;
    if (result.reason === sdk.ResultReason.RecognizingSpeech) {
      const transcript = result.text;
      setQuestion(transcript);
    }
  };

  const handleVoiceInput = () => {
    if (isListening) {
      recognizer.current.stopContinuousRecognitionAsync(() => {
        console.log("Speech recognition stopped.");
        setIsListening(false);
      });
    } else {
      recognizer.current.startContinuousRecognitionAsync(() => {
        console.log("Speech recognition started.");
        setIsListening(true);
      });
    }
  };

  const stopSpeechRecognition = (reason) => {
    if (isListening) {
      recognizer.current.stopContinuousRecognitionAsync(() => {
        console.log(`Speech recognition stopped due to ${reason}.`);
        setIsListening(false);
      });
    }
  };

  // Initialize HeyGen WebRTC session
  const initializeSession = async () => {
    try {
      console.log("Initializing session...");
      sessionInfo.current = await createNewSession();
      console.log("Session created:", sessionInfo.current);
      await startAndDisplaySession(); // Start and display the session
    } catch (error) {
      console.error("Error initializing HeyGen session:", error);
    }
  };

  const createNewSession = async () => {
    console.log("Creating new session...");
    const response = await fetch(`${HEYGEN_SERVER_URL}/v1/streaming.new`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': HEYGEN_API_KEY,
      },
      body: JSON.stringify({
        quality: 'low',
        avatar_name: HEYGEN_AVATAR_ID,
        voice: { voice_id: HEYGEN_VOICE_ID },
      }),
    });

    if (response.status !== 200) {
      console.error("Failed to create session. Status:", response.status);
      throw new Error('Failed to create session');
    }
    const data = await response.json();
    return data.data;
  };

  const startAndDisplaySession = async () => {
    if (!sessionInfo.current) {
      console.error('Session info is missing');
      return;
    }

    const { sdp: serverSdp, ice_servers2: iceServers } = sessionInfo.current;
    peerConnection.current = new RTCPeerConnection({ iceServers });

    peerConnection.current.ontrack = (event) => {
      if (event.track.kind === 'audio' || event.track.kind === 'video') {
        document.querySelector('#mediaElement').srcObject = event.streams[0];
      }
    };

    peerConnection.current.onicecandidate = ({ candidate }) => {
      if (candidate) {
        handleICE(sessionInfo.current.session_id, candidate.toJSON());
      }
    };

    const remoteDescription = new RTCSessionDescription(serverSdp);
    await peerConnection.current.setRemoteDescription(remoteDescription);

    const localDescription = await peerConnection.current.createAnswer();
    await peerConnection.current.setLocalDescription(localDescription);

    await startSession(sessionInfo.current.session_id, localDescription);
  };

  const handleICE = async (session_id, candidate) => {
    const response = await fetch(`${HEYGEN_SERVER_URL}/v1/streaming.ice`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': HEYGEN_API_KEY,
      },
      body: JSON.stringify({ session_id, candidate }),
    });

    if (response.status !== 200) {
      console.error("Failed to handle ICE candidate. Status:", response.status);
      throw new Error('Failed to handle ICE');
    }
  };

  const startSession = async (session_id, sdp) => {
    const response = await fetch(`${HEYGEN_SERVER_URL}/v1/streaming.start`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': HEYGEN_API_KEY,
      },
      body: JSON.stringify({ session_id, sdp }),
    });

    if (response.status !== 200) {
      console.error("Failed to start session. Status:", response.status);
      throw new Error('Failed to start session');
    }
  };

  const heyGenTTS = async (text) => {
    try {
      const response = await fetch(`${HEYGEN_SERVER_URL}/v1/streaming.task`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': HEYGEN_API_KEY,
        },
        body: JSON.stringify({
          session_id: sessionInfo.current.session_id,
          text: text,
        }),
      });

      if (response.status !== 200) {
        throw new Error('Failed to process TTS');
      }
    } catch (error) {
      console.error('Error in HeyGen TTS:', error);
    }
  };

  const textToSpeech = async (text) => {
    if (!isTTSEnabled) return;
    const filtered_text = text.replace(/#/g, "");
    player.current = new sdk.SpeakerAudioDestination();
    player.current.onAudioEnd = () => {
      setIsTyping(false);
    };

    const audioOutput = sdk.AudioConfig.fromSpeakerOutput(player.current);
    let synthesizer = new sdk.SpeechSynthesizer(
      speechConfig.current,
      audioOutput
    );
    synthesizer.speakTextAsync(
      filtered_text,
      (result) => {
        if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
          console.log("TTS synthesis completed");
        } else if (result.reason === sdk.ResultReason.Canceled) {
          console.error(
            `TTS synthesis canceled. Error detail: ${result.errorDetails}`
          );
        }
        synthesizer.close();
        synthesizer = undefined;
      },
      (err) => {
        console.log(`Error during TTS synthesis: ${err}`);
        synthesizer.close();
        synthesizer = undefined;
      }
    );
  };

  useEffect(() => {
    const lastMessageIndex = activeChat.queries.length - 1;
    const lastMessage = activeChat.queries[lastMessageIndex]?.solution || "";
  
    if (lastMessage && typingIndex === 0 && !skipTyping) {
      if (isLMSStyle || isLMSQuizStyle || isLMSActivityStyle) {
        console.log("Triggering TTS for LMS Activity Style:", lastMessage);
        textToSpeech(lastMessage);
      } else if (isHeygenStyle) {
        console.log("Triggering TTS for Heygen Style:", lastMessage);
        heyGenTTS(lastMessage);
      }
    }
  
    if (lastMessage && typingIndex < lastMessage.length && !skipTyping) {
      setIsTyping(true);
      const timer = setTimeout(() => {
        setDisplayedText(lastMessage.slice(0, typingIndex + 1));
        setTypingIndex(typingIndex + 1);
      }, 50);
  
      return () => clearTimeout(timer);
    } else {
      setIsTyping(false);
    }
  }, [
    activeChat.queries,
    typingIndex,
    skipTyping,
    isLMSStyle,
    isHeygenStyle,
    isLMSQuizStyle,
    isLMSActivityStyle,
  ]);
  
  

  const handleSkipTyping = () => {
    // if (!isTyping) return;
    // const lastMessageIndex = activeChat.queries.length - 1;
    // const lastMessage = activeChat.queries[lastMessageIndex]?.solution || "";

    // setDisplayedText(lastMessage);
    // setTypingIndex(lastMessage.length);
    // setIsTyping(false);
    // setSkipTyping(true);

    return;
  };

  const handleInputChange = (input) => {
    const newValue = typeof input === "string" ? input : input?.target?.value || "";
    setQuestion(newValue);
  };  
  

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleSendMessage = async (msg = question) => {
    // Ensure `msg` is a string before proceeding
    const messageToSend = typeof msg === "string" ? msg : String(msg);
  
    if (!messageToSend || isLoading || isTyping) {
      return;
    }
  
    const timestamp = new Date().toLocaleString();
    const messageWithTimestamp = `${messageToSend} (Sent at: ${timestamp})`;
  
    setQuestionList([...questionList, messageToSend]);
    timestampedQuestions.current.push(messageWithTimestamp);
  
    let payload = {
      question: messageWithTimestamp,
      modelId: activeModel,
      session_id,
    };
  
    if (!activeChat.id) {
      const splitQues = messageToSend.split(" ");
      payload.isNew = true;
      payload.title =
        splitQues[0] + " " + (splitQues[1] || "") + " " + (splitQues[2] || "");
    } else {
      payload.id = activeChat.id;
    }
  
    payload.isBubble = true;
    setIsLoading(true);
    setQuestion("");
  
    await generateBubbleChat(payload)
      .then((res) => {
        setQueries(res.data.chats);
        const oldActiveChat = res.data.chats.find(
          (chat) => chat.id === activeChat.id
        );
        const chat = oldActiveChat
          ? oldActiveChat
          : res.data.chats[res.data.chats.length - 1];
        setActiveChat(chat);
        setIsLoading(false);
        setTypingIndex(0);
        setDisplayedText("");
        setSkipTyping(false);
      })
      .catch((err) => {
        toast("Something went wrong. Please check retrain model status", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsLoading(false);
      });
  };
  

  const handleBubbleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleInputFocus = () => {
    // Stop speech recognition if the input gains focus
    stopSpeechRecognition("input focus");
  };

  const handleStopTTS = () => {
    if (player.current) {
      player.current.pause();
      setIsTyping(false);
    }
  };

  // useEffect(() => {
  //   const initialize = async () => {
  //     if (isLMSStyle || isHeygenStyle || isLMSQuizStyle || isLMSActivityStyle) {
  //       await Promise.all([
  //         isHeygenStyle ? initializeSession() : Promise.resolve(),
  //       ]);
  //     }
  //   };
  //   initialize();
  // }, [isLMSStyle, isHeygenStyle, isLMSQuizStyle, isLMSActivityStyle]);
  

    // Helper function to get common props
    const getCommonProps = () => ({
      displayedText,
      initialMessage,
      question,
      handleVoiceInput,
      isListening,
      inputRef,
      handleInputChange,
      handleKeyDown,
      handleInputFocus,
      handleSendMessage,
      isLoading,
      isTyping,
      handleSkipTyping,
      messagesContainerRef,
      setQuestion,
    });
    

// Render conditionally based on selected style
if (isLMSStyle) {
  return (
    <LMSStyle
      {...getCommonProps()}
      session_id={session_id}
      isTTSEnabled={isTTSEnabled}
      setIsTTSEnabled={setIsTTSEnabled}
      handleStopTTS={handleStopTTS}
      darkMode={darkMode}
      setDarkMode={setDarkMode}
      avatar={avatar}
      serverAddress={serverAddress}
    />
  );
} else if (isHeygenStyle) {
  return (
    <HeygenStyle
      {...getCommonProps()}
    />
  );
} else if (isLMSQuizStyle) {
  return (
    <LMSQuizStyle
      {...getCommonProps()}
      session_id={session_id}
      isTTSEnabled={isTTSEnabled}
      setIsTTSEnabled={setIsTTSEnabled}
      handleStopTTS={handleStopTTS}
    />
  );
} else if (isLMSActivityStyle) {
  return (
    <LMSActivityStyle
      {...getCommonProps()}
      session_id={session_id}
      isTTSEnabled={isTTSEnabled}
      setIsTTSEnabled={setIsTTSEnabled}
      handleStopTTS={handleStopTTS}
    />
  );
} else {
  return (
    <DefaultStyle
      isOpen={isOpen}
      avatar={avatar}
      serverAddress={serverAddress}
      questionList={questionList}
      activeChat={activeChat}
      timestampedQuestions={timestampedQuestions}
      isLoading={isLoading}
      question={question}
      handleInputChange={handleInputChange}
      handleKeyDown={handleKeyDown}
      handleSendMessage={handleSendMessage}
      handleBubbleClick={handleBubbleClick}
      messagesContainerRef={messagesContainerRef}
    />
  );
}
  }
  
  export default BubbleChat;