// DefaultStyle.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faChevronDown, faMessage } from "@fortawesome/free-solid-svg-icons";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ToastContainer } from "react-toastify";

function DefaultStyle({
  isOpen,
  avatar,
  serverAddress,
  questionList,
  activeChat,
  timestampedQuestions,
  isLoading,
  question,
  handleInputChange,
  handleKeyDown,
  handleSendMessage,
  handleBubbleClick,
  messagesContainerRef,
  setIsOpen
}) {
  return (
    <div>
      <div
        className={`flex flex-col justify-between bg-transparent rounded-t-lg mb-4 h-full w-full pt-16 pl-4 fixed right-4 bottom-12 shadow-lg border-1 rounded-lg overflow-hidden transition-transform duration-300 ${
          isOpen ? "" : "transform translate-y-full opacity-0"
        }`}
      >
        <div className="bg-sky-900 w-full h-12 flex justify-start items-center rounded-t-lg">
          <img
            src={avatar ? `${serverAddress}/avatar/${avatar}` : "/images/default_bot.png"}
            className="w-10 ml-2 rounded-lg"
          />
        </div>
        <div className="h-full overflow-y-scroll bg-white" ref={messagesContainerRef}>
          <div className={`rounded overflow-y-scroll  w-full  mx-auto md:p-0 p-4 flex flex-col`}>
            <div className="text-sm font-bold flex justify-start my-1">
              <img
                src={avatar ? `${serverAddress}/avatar/${avatar}` : "/images/default_bot.png"}
                className="w-10 h-10 rounded-lg ml-1"
              />
              <span className="font-bold ml-1 bg-gray-300 p-2 rounded-lg">{"How can I help you?"}</span>
            </div>
            {questionList.length > 0 &&
              questionList.map((message, index) => (
                <div key={index}>
                  <div className="flex flex-col items-end w-full my-1">
                    <p className="p-2 rounded-lg bg-indigo-700 text-white">{message}</p>
                  </div>
                  <div className="text-sm font-bold flex justify-start my-1">
                    <img
                      src={avatar ? `${serverAddress}/avatar/${avatar}` : "/images/default_bot.png"}
                      className="w-10 h-10 rounded-lg ml-1"
                    />
                    <span className="font-bold ml-1 bg-gray-300 p-2 rounded-lg">
                      {isLoading && questionList.length - 1 === index ? (
                        <span className="flex justify-center items-center h-full">
                          <img src="/images/dots.gif" className="w-12" />
                        </span>
                      ) : (
                        activeChat.queries
                          .filter((ans) => ans.question === timestampedQuestions.current[index])
                          .map((ans, ansIndex) => (
                            <p key={ansIndex}>
                              <Markdown remarkPlugins={[remarkGfm]}>{ans.solution}</Markdown>
                            </p>
                          ))
                      )}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="p-4 bg-white">
          <div className="w-full h-[50px] border border-gray-600 flex items-center rounded-lg p-2">
            <input
              value={question}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              className="h-full w-full p-2 outline-none bg-inherit"
              type="text"
              placeholder="Type a message..."
            />
            <button onClick={handleSendMessage} className="h-full p-2 rounded-lg icon-style text-[#ececf1]">
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </div>
          <ToastContainer />
        </div>
      </div>
      <button onClick={handleBubbleClick} className="bg-sky-900 rounded-full w-[50px] h-[50px] p-2 shadow-lg text-white fixed bottom-1 right-4">
        <FontAwesomeIcon icon={isOpen ? faChevronDown : faMessage} />
      </button>
    </div>
  );
}

export default DefaultStyle;
