import React from "react";

const SendChatIcon = () => (
    <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.85 7.05L30.1166 14.1833C36.5166 17.3833 36.5166 22.6167 30.1166 25.8167L15.85 32.95C6.24995 37.75 2.33328 33.8167 7.13328 24.2333L8.58328 21.35C8.94995 20.6167 8.94995 19.4 8.58328 18.6667L7.13328 15.7667C2.33328 6.18334 6.26662 2.25 15.85 7.05Z"
      stroke="#212121"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.06665 20H18.0666"
      stroke="#212121"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SendChatIcon;
