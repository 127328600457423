import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faMicrophone, faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import SpeakerOnIcon from "./icons/SpeakerOnIcon";
import SpeakerOffIcon from "./icons/SpeakerOffIcon";
import ChatHistoryIcon from "./icons/ChatHistoryIcon";
import SendChatIcon from "./icons/SendChatIcon";
import CaptionIcon from "./icons/CaptionIcon";

function LMSActivityStyle({
  handleStopTTS,
  displayedText,
  initialMessage,
  question,
  inputRef,
  handleInputChange,
  handleKeyDown,
  handleInputFocus,
  handleSendMessage,
  isLoading,
  isTyping,
  handleSkipTyping,
  messagesContainerRef,
  session_id,
  isTTSEnabled,
  setIsTTSEnabled,
  handleVoiceInput,
  isListening
}) {
  const [placeholderText, setPlaceholderText] = useState("Ask me a question! 😊");
  const placeholderOptions = [
    "Ask me a question! 😊",
    "Say something fun! 🤔",
    "What do you want to know? 💫",
    "Let’s get started! 🚀",
    "I'm here to help! 😊",
  ];
  const [showStartMessage, setShowStartMessage] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderText((prev) => {
        const currentIndex = placeholderOptions.indexOf(prev);
        const nextIndex = (currentIndex + 1) % placeholderOptions.length;
        return placeholderOptions[nextIndex];
      });
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isTTSEnabled && displayedText) {
      console.log("TTS Start: ", displayedText);
    }
    if (displayedText) {
      setShowStartMessage(false);
    }
  }, [displayedText, isTTSEnabled]);

  const handleTTSButtonClick = () => {
    setIsTTSEnabled((prev) => !prev);
  };

  const extractOptions = (text) => {
    const options = {};
    const modifiedText = text.replace(/([1-9])\)\s*(.*?)(?=(?:[1-9]\)|$))/g, (match, option, answer) => {
      const sanitizedAnswer = String(answer).replace(/[?,]/g, "").trim();
      options[option] = sanitizedAnswer;
      return "";
    });
    return { options, modifiedText: modifiedText.trim() };
  };

  const { options, modifiedText } = extractOptions(displayedText);

  const handleActivityButtonClick = (value) => {
    if (!isLoading && !isTyping) {
      handleInputChange({ target: { value } });
      handleSendMessage(value);
      if (isTTSEnabled) console.log("TTS Start: ", value);
    }
  };

  const handleInputSendMessage = () => {
    handleSendMessage();
    if (isTTSEnabled) console.log("TTS Start: ", question);
  };

  const handleStartButtonClick = () => {
    handleInputChange({ target: { value: "start" } });
    handleSendMessage(
      "Message from System: start the user over from the beginning as if they are just now speaking to you for the first time."
    );
    setShowStartMessage(false);
  };

  return (
    <div
      className="fixed inset-0 flex flex-col"
      style={{ backgroundColor: "#73B9AF", margin: 0, padding: 0 }}
    >
      {/* Top Bar for Icons */}
      <div className="flex items-center justify-between p-4 relative z-10">
        <div className="flex items-center gap-8">
          {/* Dynamic TTS Button */}
          <button
            onClick={handleTTSButtonClick}
            className="p-0 transition duration-300"
            aria-label={isTTSEnabled ? "Mute TTS" : "Unmute TTS"}
          >
            {isTTSEnabled ? <SpeakerOnIcon /> : <SpeakerOffIcon />}
          </button>
        </div>
      </div>

      {/* Avatar and Chat Area */}
      <div
        className="absolute inset-0 w-full h-[50%] flex flex-col overflow-hidden"
        style={{ backgroundColor: "#73B9AF" }}
      >
        <div
          className="absolute top-0 left-0 w-full h-full flex items-center justify-center"
          style={{ alignSelf: "center" }}
        >
          <div
            style={{
              height: "100%",
              overflow: "hidden",
              paddingTop: "12px",
              paddingBottom: "12px",
            }}
          >
            <img
              src={
                isTyping
                  ? "https://i.imgur.com/uZjHxuK.gif"
                  : "https://i.imgur.com/RjCt4hf.gif"
              }
              alt="Avatar Animation"
              style={{
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      </div>

      {/* Bottom Section: Chat Area and Buttons */}
      <div
        className="absolute bottom-0 w-full flex flex-col items-center justify-start p-8 z-10"
        style={{ backgroundColor: "#ECECEC", height: "50%" }}
      >
        {/* Displayed Text */}
        {displayedText && (
          <div
            className="p-4 bg-[#73B9AF] text-black text-lg md:text-xl rounded-lg shadow-md break-words cursor-pointer"
            onClick={handleSkipTyping}
            style={{
              fontFamily: "Inter, sans-serif",
              fontSize: "20px",
              fontWeight: "500",
              lineHeight: "normal",
              color: "#000",
              textAlign: "center",
              background: "#73B9AF",
              backdropFilter: "blur(30px)",
              marginBottom: "16px",
              width: "100%",
            }}
          >
            <span>{modifiedText}</span>
          </div>
        )}

        {/* Voice Input and Buttons Section */}
        <div
          className="flex flex-col items-center justify-center"
          style={{ width: "100%", marginBottom: "16px" }}
        >
          {showStartMessage ? (
            <div className="flex flex-col items-center">
              <div className="p-4 bg-[#AFD3CD] text-black text-center text-lg md:text-xl rounded-lg shadow-md mb-4 break-words">
                <p>
                  Press the "Start" button to begin chatting! Once you start, you can
                  type your questions or click the buttons that pop up to choose an
                  answer. Click the speaker button to mute or hear the bot.
                </p>
              </div>
              <button
                onClick={handleStartButtonClick}
                className="w-full py-4 bg-white text-black text-2xl font-bold shadow-md rounded-lg hover:bg-[#AFD3CD] hover:text-black"
                style={{
                  margin: "0 24px",
                  textAlign: "center",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                Start
              </button>
            </div>
          ) : (
            Object.keys(options).length > 0 && (
              <div
                className="flex flex-wrap w-full"
                style={{ justifyContent: "center", alignItems: "stretch", marginBottom: "16px" }}
              >
                {Object.entries(options).map(([key, value]) => (
                  <button
                    key={key}
                    onClick={() => handleActivityButtonClick(value)}
                    className="hover:bg-[#AFD3CD]"
                    style={{
                      flex: "1 0 30%",
                      margin: "8px",
                      minHeight: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "8px",
                      fontWeight: "500",
                      fontSize: "20px",
                    }}
                  >
                    {value}
                  </button>
                ))}
              </div>
            )
          )}
        </div>

        {/* Input and Send Button */}
        <div
          className="flex items-center w-full relative"
          style={{
            position: "fixed",
            bottom: "32px",
            left: "24px",
            right: "24px",
            maxWidth: "calc(100% - 48px)",
          }}
        >
          <input
            ref={inputRef}
            value={question}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={handleInputFocus}
            className="flex-grow h-12 pl-12 pr-10 rounded-full text-xl focus:outline-none transition duration-300"
            type="text"
            placeholder={placeholderText}
            disabled={showStartMessage}
            style={{ color: "#333", backgroundColor: "#FFFFFF" }}
          />
          {/* Voice Input Button inside the input box */}
          <button
            onClick={handleVoiceInput}
            className={`absolute left-3 inset-y-0 my-auto h-8 w-8 flex items-center justify-center rounded-full bg-transparent text-black ${isListening ? "pulsating" : ""}`}
            aria-label="Voice Input"
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon
              icon={isListening ? faMicrophoneSlash : faMicrophone}
              style={{ color: "#000", fontSize: "16px", lineHeight: "16px" }}
              className="fa-fw"
            />
          </button>

          {/* Send Button */}
          <button
            onClick={handleInputSendMessage}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 flex items-center justify-center"
            disabled={isLoading || isTyping || !question.trim() || showStartMessage}
            aria-label="Send Message"
          >
            {isLoading || isTyping ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <SendChatIcon />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default LMSActivityStyle;
